import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import styles from "./index.module.scss";


function CustomToggle({ eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>null );
    
    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className={styles.accordionArrow}
        >
            <img src="./img/accordion_down_arrow.png" alt="arrow"/>
        </button>
    );
}



class MyAccordion extends Component {
  render() {
      return (
          <Accordion  defaultActiveKey={(this.props.alwaysOpen)?this.props.id:null} className={styles.accordion}>
              <Card className={styles.accordionContentContainer}>
                  <Card.Header className={styles.accordionHeader}>
                      {this.props.title}
                      {(!this.props.alwaysOpen) ? <CustomToggle eventKey={this.props.id} /> : null}
                  </Card.Header>
                  <Accordion.Collapse eventKey={this.props.id}>

                      <Card.Body className={styles.accordionBody}>
                          <div className={styles.subtitle}>{this.props.subtitle}</div>
                          {this.props.children}
                      </Card.Body>
                  </Accordion.Collapse>
              </Card>
          </Accordion>
      );
  }
}

MyAccordion.defaultProps = {
    id: 0,
    alwaysOpen: false,
    title:"TITOLO",
    subtitle:null
};


export default MyAccordion;
