import React, {Component} from "react";
import styles from "./index.module.scss";
import {HashLink as Link} from 'react-router-hash-link';

class Header extends Component {
  render() {
    return (
        <div className={styles.container}>
          <div className={styles.containerLeft}>
            <img src={this.props.extraLayer + "img/logo_header.png"} alt="wearecar" onClick={() => window.location.href = "/"} style={{cursor: "pointer"}}/>
          </div>
          <div className={styles.containerRight}>
            <Link to={"/#come-funziona"} className={styles.link}>
              Come Funziona
            </Link>
            <Link to={"/listing"} className={styles.link}>
              Offerte
            </Link>
            <Link to={"/contact-us"} className={styles.button}>
              Contattaci
            </Link>
          </div>
        </div>
    );
  }
}

Header.defaultProps = {
  extraLayer: ""
};


export default Header;
