import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";

import Home from "./routes/home";
import Listing from "./routes/listing";
import Ad from "./routes/ad";
import ContactUs from "./routes/contact-us";
import Offer from "./routes/offer";
import {HelmetProvider} from "react-helmet-async";

function App() {
  const helmetContext = {};

  return (
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="listing" element={<Listing/>}/>
            <Route path="ad/:id" element={<Ad/>}/>
            <Route path="offerte/:slug" element={<Offer/>}/>
            <Route path="contact-us" element={<ContactUs/>}/>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
  );
}

export default App;
