import React, {Component} from "react";
import {useLocation} from "react-router-dom";
import styles from "../listing/index.module.scss";
import Header from "../../components/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer";

export function withRouter(Children) {
  return (props) => {
    const location = {params: useLocation()};
    return <Children {...props} location={location}/>;
  };
}

class ContactUs extends Component {
  render() {
    return (
        <div
            className={styles.mainContainer}
            style={{backgroundImage: " url('img/listing_bg.png')"}}
        >
          <Header/>
          <Container className={"my-5 text-center text-md-start"}>
            <Row>
              <Col sm="12" lg="6">
                <div className={"title-card mb-4"}>
                  Contattaci
                </div>
                <div className={"light-text text-uppercase"}>
                  Ciao, piacere di conoscerti: siamo Wearecar.<br/>
                  Se desideri avere maggiori informazioni sui nostri servizi o vuoi metterti in contatto con noi per qualsiasi argomento, sei nel posto giusto.
                </div>
              </Col>
            </Row>
          </Container>
          <iframe
              className={styles.iframe}
              scrolling={"no"}
              title="chatbot"
              src="https://chats.spinup.media/c/wearecar-contact-us"
          />
          <Footer/>
        </div>
    );
  }
}

export default withRouter(ContactUs);