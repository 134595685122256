import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class AdContainer extends Component {
  render() {
      return (
          <Container fluid>
              <Row>
                  {this.props.children.map((el, index) => {
                      return (
                          <Col key={"ad-n-" + index} sm="12" md="6" className={""}>
                              {el}
                          </Col>
                      )
                  })}
              </Row>
          </Container>
      );
  }
}


export default AdContainer;
