import React, { Component } from "react";
import styles from "./index.module.scss";

import Header from "../../components/header";
import Footer from "../../components/footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import RangeSlider from "../../components/rangeSlider";

import { ajaxGet } from "../../helper/Ajax.js";
import { Link } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceRange: [0, 100000],
      brandList: [],
      categoryList: [],
      loaded: false,

      filters: {
        priceRange: [0, 100000],
        category: null,
        brand: null,
      },
    };
  }

  componentDidMount = () => {
    ajaxGet("home", this.apiCallback);
  };

  apiCallback = (response) => {
    let temp = this.state.filters;
    temp.priceRange = [
      response.data.filters.price_range.min,
      response.data.filters.price_range.max,
    ];

    this.setState({
      brandList: response.data.filters.brands,
      categoryList: response.data.filters.categories,
      priceRange: [
        response.data.filters.price_range.min,
        response.data.filters.price_range.max,
      ],
      filters: temp,
      loaded: true,
    });
  };

  whoAreWe = () => {};

  contactUs = () => {};

  render() {
    return (
      <>
        <div
          className={styles.section1}
          style={{ backgroundImage: " url('img/home_bg.png')" }}
        >
          <Header />
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <div className={"title-card text-center text-md-start"}>
                  L'auto giusta a un{" "}
                  <span className={styles.primary}>click</span> da te
                </div>
                            <div className={"light-text text-center text-md-start"}>
                  Sei nel posto giusto, trova l'auto su misura per te
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="8">
                <div className={styles.filterContainer}>
                  <div className={styles.filterMiniTitle}>Cerca Per</div>
                  <div className={styles.filterRow}>
                    <div className={styles.filterLabel}>Prezzo</div>
                    <div className={styles.filterSliderContainer}>
                      {this.state.loaded ? (
                        <RangeSlider
                          min={this.state.priceRange[0]}
                          max={this.state.priceRange[1]}
                          onChange={(value) => {
                            this.setState((prevState) => ({
                              filters: {
                                ...prevState.filters,
                                priceRange: value,
                              },
                            }));
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.filterDivider}></div>
                  <div className={styles.filterRow}>
                    <div className={styles.filterLabel}>Brand</div>
                    <div className={styles.filterIcon}></div>
                    <select
                      className={styles.filterSelect}
                      defaultValue=""
                      onChange={(value) => {
                        this.setState((prevState) => ({
                          filters: {
                            ...prevState.filters,
                            brand: value.target.value,
                          },
                        }));
                      }}
                    >
                      <option value="" disabled hidden>
                        BMW, mercedes ...
                      </option>
                      {this.state.brandList.map((el, index) => {
                        return (
                          <option key={"brand-" + index} value={el.value}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={styles.filterRow}>
                    <div className={styles.filterLabel}>Categoria</div>
                    <div className={styles.filterIcon}>
                      <img src="img/filter_car.png" alt="filter-car" />
                    </div>
                    <select
                      className={styles.filterSelect}
                      defaultValue=""
                      onChange={(value) => {
                        this.setState((prevState) => ({
                          filters: {
                            ...prevState.filters,
                            category: value.target.value,
                          },
                        }));
                      }}
                    >
                      <option value="" disabled hidden>
                        utilitaria, berlina ...
                      </option>
                      {this.state.categoryList.map((el, index) => {
                        return (
                          <option key={"category-" + index} value={el.value}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Link
                    to={"/listing"}
                    state={this.state.filters}
                    className={styles.filterSearch}
                  >
                    Cerca
                  </Link>
                </div>
              </Col>
              <Col sm="12" lg="6" className={"text-center text-md-start"}></Col>
            </Row>
          </Container>
        </div>

        <div
          className={styles.section2}
                style={{ backgroundImage: " url('img/abstract.png')" }}
                id="come-funziona"
        >
          <Container>
            <Row className="align-items-center">
              <Col sm="12" lg="6" className={"text-center"}>
                <img src="./img/our_service.png" alt="our services" />
              </Col>
              <Col sm="12" lg="6" className={"text-center text-md-start"}>
                <h2>
                  Come <span className={styles.primary}>Funziona</span>
                </h2>
                <p className={styles.primary}>Perché scegliere WeAreCar?</p>
                <p>
                  Perché siamo una società fatta da chi ama i motori, da chi
                  cerca sempre il meglio. Ci piace l'idea di selezionare delle
                  auto "tailor-made" in base alle tue reali esigenze.
                </p>
                <p>
                  WeAreCar ti permette di configurare la tua auto ideale,
                  confronta e richiedi un preventivo gratuito senza impegno.
                  Siamo costantemente aggiornati sulle offerte e sulle novità
                  del settore con gli ultimi modelli disponibili. Se sei ancora
                  indeciso sulla tua prossima auto da acquistare o da
                  noleggiare, potrai utilizzare il nostro configuratore per
                  scoprire il modello ideale per te
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={styles.section3}
          style={{ backgroundImage: " url('img/contact_us_bg.png')" }}
        >
          <Container>
            <div
              className={styles.internalContainer}
              style={{ backgroundImage: " url('img/abstract.png')" }}
            >
              <div className={styles.contactUs}>Non esitare a contattarci </div>
              <Link to={"/contact-us"} className={'my-button'}>
                Contattaci
              </Link>
            </div>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;
