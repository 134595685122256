import styles from "./index.module.scss";
import React from "react";
import { useController } from "react-hook-form";

export function InputTextComponent({ data }) {
  let { field, fieldState } = useController({
    name: data.name,
    rules: Rules[data.type],
    defaultValue: "",
  });

  return (
    <div className="was-validated">
      <label htmlFor={data.name} className={styles.formLabel}>
        {data.label}
      </label>
      <input
        // TODO: Here we receive types that are invalid for input e.g. cap
        type={data.type}
        placeholder={data.placeholder}
        className={[
          styles.formInput,
          fieldState.error ? "is-invalid" : "",
        ].join(" ")}
        id={data.name}
        {...field}
      />
      <div className="invalid-feedback">{fieldState.error?.message}</div>
    </div>
  );
}

// https://github.com/gtournie/redux-form-validators/blob/61f4c95770daef956048484395cc12a39b765fe5/src/email.js#L5
let REG_EMAIL =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@((?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;

let Rules = {
  text: {
    required: "Campo obbligatorio",
    minLength: {
      value: 2,
      message: "Inserire almeno 2 caratteri",
    },
    pattern: {
      value: /^\p{L}+$/u,
      message: "Sono ammessi solo caratteri alfabetici",
    },
  },
  phone: {
    required: "Inserisci il tuo numero di cellulare",
    pattern: {
      value: /^\d+$/,
      message: 'Il campo può contenere solo numeri ed iniziare con "3"',
    },
  },
  cap: {
    required: "Inserisci il tuo CAP",
    pattern: {
      value: /^\d{5}$/,
      message: "Il campo può contenere solo numeri ed avere 5 cifre",
    },
  },
  email: {
    required: "Inserisci il tuo indirizzo email",
    pattern: {
      value: REG_EMAIL,
      message: "Indirizzo email non valido",
    },
  },
};
