import React, { Component } from "react";
import styles from "./index.module.scss";


class AdListing extends Component {
    render() {
        return (
            <div className={styles.container} onClick={() => window.location.href = "/ad/" + this.props.data.value}>
                <div className={styles.imageContainer}>
                    <img src={this.props.data.image} alt={this.props.data.brand} />
                </div>
                <div className={styles.brandContainer}>
                    <div className={styles.brand}>{this.props.data.brand}</div>
                    <div className={styles.model}>{this.props.data.name}</div>
                </div>
                <div className={styles.infoContainer}>
                    <div className={styles.infoContainerLeft}>
                        {this.props.data.options.map((el, index) => {
                            return (
                                <div key={"info-"+index} className={styles.info}>
                                    <b>{el.label} </b>
                                    {el.value}
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.infoContainerRight}>
                        <div className={styles.price}>
                            {this.props.data.price.value}
                            {"\u20AC"}
                        </div>
                        <div className={styles.iva}>{this.props.data.price.label}</div>
                    </div>
                </div>
            </div>
        );
    }
}

AdListing.defaultProps = {
  img: "https://cdn.motor1.com/images/mgl/Qee9rN/s3/dodge-charger-daytona-srt-concept.jpg",
  brand: "brand auto",
  model: "Full model name",
  fuel: "Benzina, diesel",
  doors: "3|5",
  price: "320",
};

export default AdListing;
