import styles from "./index.module.scss";
import React from "react";

export function InputSelectComponent({ data, required = true }) {
  return (
    <div>
      <label htmlFor={data.name} className={styles.formLabel}>
        {data.label}
      </label>
      <select
        className={styles.formSelect}
        name={data.name}
        id={data.name}
        required={required}
      >
        {data.options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
