
const PATH = "https://aged-grass-sfqsfvs8vusu.vapor-farm-a1.com/api/v1/";

export const ajaxGet = (url, callback, config) => {
    fetch(PATH + url, config, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if (callback) {
                callback(responseJson);
            }
        })
        .catch((error) => {
            console.log(error);
            //Catch Service Error
        });
};

export const ajaxPost = (url, formData, callback) => {
    fetch(PATH + url, {
        method: "POST",
        body: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if (callback) {
                callback(responseJson);
            }
        })
        .catch((error) => {
            console.log(error);
            //Catch Service Error
        });
};