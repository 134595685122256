import styles from "./index.module.scss";
import ReactMarkdown from "react-markdown";
import React from "react";

export function Disclaimer({ data }) {
  return (
    <div className={styles.checkboxContainer}>
      <div className={styles.formCheckboxLabel}>
        <ReactMarkdown children={data.text}></ReactMarkdown>
      </div>
    </div>
  );
}
