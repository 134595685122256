import styles from "./index.module.scss";
import ReactMarkdown from "react-markdown";
import React from "react";

export function DisclaimerCheckbox({ data }) {
  return (
    <>
      {data.values ? (
        <>
          <div className={styles.checkboxContainer}>
            <div className={styles.formCheckboxLabel}>
              <ReactMarkdown children={data.text}></ReactMarkdown>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "grid",
              gap: "2",
              padding: "0px 20px",
              fontSize: "14px",
            }}
          >
            {data.values?.map((el, index) => (
              <div
                key={data.name + "_" + index}
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <input
                  type="radio"
                  id={data.name}
                  name={data.name}
                  value={el.value}
                  required={data.required}
                />
                <label for={data.name}>{el.label}</label>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={styles.checkboxContainer}>
            <input
              required={data.required}
              type="checkbox"
              name={data.name}
              className={styles.formCheckbox}
            />
            <div className={styles.formCheckboxLabel}>
              <ReactMarkdown children={data.text}></ReactMarkdown>
            </div>
          </div>
        </>
      )}
    </>
  );
}
