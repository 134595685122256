import { InputSelectComponent } from "./InputSelectComponent";
import { InputTextComponent } from "./InputTextComponent";
import { InputHiddenComponent } from "./InputHiddenComponent";

export function getFieldComponent(type) {
  const FieldsMap = {
    select: InputSelectComponent,
    hidden: InputHiddenComponent,
  };
  // There can be also email, phone, and other types
  return FieldsMap[type] ?? InputTextComponent;
}
