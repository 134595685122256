import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";

import styles from "./index.module.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { ajaxGet } from "../../helper/Ajax.js";
import { getFieldComponent } from "../../components/inputs";
import { Disclaimer } from "../../components/inputs/Disclaimer";
import { DisclaimerCheckbox } from "../../components/inputs/DisclaimerCheckbox";
import { useForm, FormProvider } from "react-hook-form";

let cardMargin = 25;

export default function Ad() {
  let { id } = useParams();
  let [loaded, setLoaded] = useState(false);
  let [data, setData] = useState({});
  let [submitStatus, setSubmitStatus] = useState(undefined);
  let formMethods = useForm({ mode: "onBlur" });

  useEffect(() => {
    ajaxGet(`detail/${id}`, (response) => {
      setLoaded(true);
      setData(response.data);
    });
  }, [id]);

  async function onSubmit(_values, e) {
    /** @type {HTMLFormElement} */
    let form = e.target;
    // We are using form instead of values, because there are unregistered fields
    let formData = new FormData(form);

    let response = await fetch(form.action, {
      method: form.method,
      body: formData,
    });

    let data = await response.json();

    let status = data.success === true ? "OK" : "ERROR";
    setSubmitStatus(status);
  }

  if (!loaded) return null;

  // Data is loaded as null when id is wrong
  if (!data) {
    return (
      <div className={styles.wrapper}>
        <Header extraLayer="../" />
        <Container>
          <h4 className="my-4 text-center">Something went wrong</h4>
        </Container>
        <Footer extraLayer="../" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Header extraLayer="../" />

      <Container className={"mt-5"}>
        <Row>
          <Col sm="12" lg="6">
            <div className={styles.carModelContainer}>
              <div className={styles.carModelTextContainer}>
                <div className={styles.carModelBrand}>
                  {data.brand} {data.name}
                </div>
                <div className={styles.carModelDetail}>{data.version}</div>
              </div>
            </div>
            <div className={styles.carModelDescription}>
              <Carousel
                className={styles.carousel}
                controls={data.image?.length > 0}
                indicators={data.image?.length > 0}
              >
                {data.images.map((el, index) => (
                  <Carousel.Item key={"slide-" + index}>
                    <img
                      className="d-block w-100"
                      src={el}
                      alt={"slide-" + index}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <div className={styles.card} style={{ marginTop: cardMargin }}>
                <div className={styles.cardTitle}>Caratteristiche generali</div>
                <div className={styles.cardDivider} />
                <Row>
                  {data.features.map((feature, index) => (
                    <Col sm="12" lg="12" key={"features-col-" + index}>
                      <LabelValueRow data={feature} />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Col>
          <Col sm="12" lg="6" className={`mt-4 mt-lg-0 ${styles.rightSide}`}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>Dettagli modello</div>
              <div className={styles.cardDivider} />
              {data.details.map((el, index) => (
                <LabelValueRow data={el} key={index} />
              ))}
              <div className={styles.cardDivider} />
              <div className={styles.price}>
                <div className={styles.priceDescription}>a partire da</div>
                {data.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                {"\u20AC"}
              </div>
            </div>
            {/* Some data is missing fields and privacy and page is crashing */}
            {/* Default to not available */}
            {data.form && data.form.fields ? (
              <div
                className={styles.card}
                style={{ marginTop: cardMargin, flexGrow: 1 }}
              >
                <div className={styles.cardTitle}>
                  scegli <b>{data.form?.title}</b>
                </div>
                <div className={styles.cardDivider} />
                {submitStatus === "ERROR" && (
                  <div className={styles.formSubmitMessage}>
                    Something went wrong
                  </div>
                )}
                {submitStatus === "OK" && (
                  <div className={styles.formSubmitMessage}>
                    Grazie! Sarai ricontattato al più presto.
                  </div>
                )}
                {submitStatus === undefined && (
                  <FormProvider {...formMethods}>
                    <form
                      className={styles.form}
                      action={data.form.url}
                      method={data.form.method}
                      onSubmit={formMethods.handleSubmit(onSubmit)}
                    >
                      <div className="w-100">
                        {(data.form.fields ?? []).map((el, index) => {
                          let Field = getFieldComponent(el.type);
                          return <Field data={el} key={index} />;
                        })}
                      </div>
                      {/* Some data is missing privacy and page is crashing */}
                      {(data.form.privacy ?? []).map((el, index) => {
                        return el.onlyText === true ? (
                          <Disclaimer data={el} key={index} />
                        ) : (
                          <DisclaimerCheckbox data={el} key={index} />
                        );
                      })}
                      <button className={styles.formButton}>
                        {data.form.button}
                      </button>
                    </form>
                  </FormProvider>
                )}
              </div>
            ) : (
              <div className={styles.card} style={{ marginTop: cardMargin }}>
                <div className={styles.cardTitle}>
                  offerta al momento non disponibile
                </div>
                <div className={styles.cardDivider} />
                <Row>
                  <Col sm="12" lg="12" className={"text-uppercase text-center"}>
                    <b>Torna a trovarci presto!</b>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {data.conditions && (
        <Container className={"my-4"}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              Condizioni generali dell'offerta
            </div>
            <div className={styles.cardDivider} />
            <div className={styles.cardText}>
              <ReactMarkdown children={data.conditions} />
            </div>
          </div>
        </Container>
      )}

      <Footer extraLayer="../" />
    </div>
  );
}

function LabelValueRow({ data }) {
  return (
    <div className={styles.detailRow}>
      <Col sm="12" lg="12" className={"text-lowercase"}>
        {data.label} <span className={styles.detailBold}>{data.value}</span>
      </Col>
    </div>
  );
}
