import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";
import styles from "./index.module.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { ajaxGet } from "../../helper/Ajax.js";
import { getFieldComponent } from "../../components/inputs";
import { Disclaimer } from "../../components/inputs/Disclaimer";
import { DisclaimerCheckbox } from "../../components/inputs/DisclaimerCheckbox";
import { useForm, FormProvider } from "react-hook-form";
import HeaderOffers from "../../components/header-offers";
import {Helmet} from "react-helmet-async";

let cardMargin = 25;

export default function Offer() {
  let { slug } = useParams();
  let [loaded, setLoaded] = useState(false);
  let [data, setData] = useState({});
  let [conversionId, setConversionId] = useState("");
  let [conversionLabel, setConversionLabel] = useState("");
  let [submitStatus, setSubmitStatus] = useState(undefined);
  let formMethods = useForm({ mode: "onBlur" });

  useEffect(() => {
    ajaxGet(`offers/${slug}`, (response) => {
      setLoaded(true);
      setData(response.data);
      setConversionId(response.data.gtm_conversion_id)
      setConversionLabel(response.data.gtm_conversion_label)
    });
  }, [slug]);

  async function onSubmit(_values, e) {
    /** @type {HTMLFormElement} */
    let form = e.target;
    // We are using form instead of values, because there are unregistered fields
    let formData = new FormData(form);
    let url = new URL(window.location);

    for (let [key, value] of url.searchParams) {
      let noInput = !formData.has(key);
      if (noInput) formData.append(key, value);
    }

    formData.append("_page_url", url.href.split("?")[0]);

    let response = await fetch(form.action, {
      method: form.method,
      body: formData,
    });

    let data = await response.json();

    let status = data.success === true ? "OK" : "ERROR";
    setSubmitStatus(status);
    if (status && conversionId && conversionLabel) {
      window.dataLayer.push({
        event: 'brain',
        conversionId: conversionId,
        conversionLabel: conversionLabel
      });
    }
  }

  if (!loaded) return null;

  // Data is loaded as null when slug is wrong
  if (!data) {
    return (
      <div className={styles.wrapper}>
        <Header extraLayer="../" />
        <Container>
          <h4 className="my-4 text-center">Something went wrong</h4>
        </Container>
        <Footer extraLayer="../" />
      </div>
    );
  }

  return (
      <>
        <Helmet>
          <title>{data.title} @ Wearecar</title>
        </Helmet>
        <div className={styles.wrapper}>
          <HeaderOffers extraLayer="../"/>

      <Container className={"mt-5"}>
        <Row>
          <Col sm="12" lg="12">
            <div className={"title-card text-center text-md-start"}>
              {data.heading}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="12">
            <div className={"text-center text-md-start primarysub"}>
              {data.sub_heading}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <div className={styles.carModelContainer}>
              <div className={styles.carModelTextContainer}>
                <div className={styles.carModelBrand}>
                  {data.brand} {data.name}
                </div>
                <div className={styles.carModelDetail}>{data.version}</div>
              </div>
            </div>
            <div className={styles.carModelDescription}>
              <img
                className="d-block w-100"
                src={data.image_url}
                alt={data.title}
              />
              <div className={styles.card} style={{ marginTop: cardMargin }}>
                <div className={styles.cardTitle}>
                  <ReactMarkdown children={data.description} />
                </div>
              </div>
            </div>
          </Col>
          <Col sm="12" lg="6" className={`mt-4 mt-lg-0 ${styles.rightSide}`}>
            {data.active ? (
              <div
                className={styles.card}
                style={{ marginTop: cardMargin, flexGrow: 1 }}
              >
                <div className={styles.cardTitle}>
                  <b>{data.claim}</b>
                </div>
                <div className={styles.cardDivider} />
                {submitStatus === "ERROR" && (
                  <div className={styles.formSubmitMessage}>
                    Something went wrong
                  </div>
                )}
                {submitStatus === "OK" && (
                  <div className={styles.formSubmitMessage}>
                    Grazie! Sarai ricontattato al più presto.
                    <div dangerouslySetInnerHTML={{__html: data.tp_code}} />
                  </div>
                )}
                {submitStatus === undefined && (
                  <FormProvider {...formMethods}>
                      <form
                      encType="application/json"
                      className={styles.form}
                      action={data.url}
                      method={data.method}
                      onSubmit={formMethods.handleSubmit(onSubmit)}
                    >
                      <div className="w-100">
                        {data.fields.map((el, index) => {
                          let Field = getFieldComponent(el.type);
                          return <Field data={el} key={index} />;
                        })}
                      </div>
                      <input
                        type={"hidden"}
                        name={"_page_name"}
                        value={`Landing ${data.slug}`}
                      />
                      <input
                        type={"hidden"}
                        name={"_page_type"}
                        value={`Landing`}
                      />
                      {data.disclaimer?.map((el, index) => (
                        <Disclaimer data={el} key={index} />
                      ))}

                              {data.privacy?.map((el, index) => (
                                  <DisclaimerCheckbox data={el} key={index}/>
                              ))}
                              <button className={styles.formButton}>{data.cta}</button>
                            </form>
                          </FormProvider>
                      )}
                    </div>
                ) : (
                    <div className={styles.card} style={{marginTop: cardMargin}}>
                      <div className={styles.cardTitle}>
                        offerta al momento non disponibile
                      </div>
                      <div className={styles.cardDivider}/>
                      <Row>
                        <Col sm="12" lg="12" className={"text-uppercase text-center"}>
                          <b>Torna a trovarci presto!</b>
                        </Col>
                      </Row>
                    </div>
                )}
              </Col>
            </Row>
          </Container>

          {data.conditions && (
              <Container className={"my-4"}>
                <div className={styles.card}>
                  <div className={styles.cardTitle}>
                    Condizioni generali dell'offerta
                  </div>
                  <div className={styles.cardDivider}/>
                  <div className={styles.cardText}>
                    <ReactMarkdown children={data.conditions}/>
                  </div>
                </div>
              </Container>
          )}

          <Footer extraLayer="../"/>
        </div>
      </>
  );
}
