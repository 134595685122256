import React, {Component} from "react";
import styles from "./index.module.scss";

class HeaderOffers extends Component {
  render() {
    return (
        <div className={styles.container}>
          <div className={styles.containerCenter}>
            <img src={this.props.extraLayer + "img/logo_header.png"} alt="wearecar" onClick={() => window.location.href = "/"} style={{cursor: "pointer"}}/>
          </div>
        </div>
    );
  }
}

HeaderOffers.defaultProps = {
  extraLayer: ""
};


export default HeaderOffers;
