import React, { Component } from "react";
import AdListing from "../../components/AdListing";
import AdContainer from "../../components/AdContainer";

import styles from "./index.module.scss";

import Header from "../../components/header";
import Footer from "../../components/footer";
import MyAccordion from "../../components/accordion";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import RangeSlider from "../../components/rangeSlider";

import { ajaxGet, ajaxPost } from "../../helper/Ajax.js";
import { useLocation } from "react-router-dom";

export function withRouter(Children) {
  return (props) => {
    const location = { params: useLocation() };
    return <Children {...props} location={location} />;
  };
}

class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceRange: [0, 100000],
      carList: [],
      loaded: false,

      filters: {
        priceRange: this.props.location.params.state
          ? this.props.location.params.state.priceRange
          : [0, 100],
        category_id: this.props.location.params.state
          ? this.props.location.params.state.category
          : null,
        brand_id: this.props.location.params.state
          ? this.props.location.params.state.brand
          : null,
      },

      filterList: [],
    };
    this.slider = null;
  }

  componentDidMount = () => {
    ajaxGet("home", this.apiCallback);
    ajaxGet("listing", this.filterCallback);
  };

  apiCallback = (response) => {
    this.setState(
      {
        brandList: response.data.filters.brands,
        categoryList: response.data.filters.categories,
        priceRange: [
          response.data.filters.price_range.min,
          response.data.filters.price_range.max,
        ],
        loaded: true,
      },
      this.search
    );
  };

    search = () => {
    let filter = "?price_min=" + this.state.filters.priceRange[0];
    filter += "&price_max=" + this.state.filters.priceRange[1];

    for (const key in this.state.filters) {
      if (key === "priceRange" || !this.state.filters[key]) {
        continue;
      }
      filter += "&" + key + "=" + this.state.filters[key];
    }

    ajaxPost("search/" + filter, null, this.searchCallback);
  };

  searchCallback = (response) => {
    this.setState({ carList: response.data });
  };

  filterCallback = (response) => {
    this.setState({ filterList: response.data.filters });
  };

  renderFiltersContent = (fields) => {
    return fields.map((field, indexx) => {
      return (
        <select
          key={"select-" + field.label + "-" + indexx}
          className={styles.filterSelect}
          value={
            !this.state.filters[field.name]
              ? ""
              : this.state.filters[field.name]
          }
          onChange={(value) => {
            let temp = this.state.filters;
            temp[field.name] = value.target.value;
            this.setState({ filters: temp }, this.search);
          }}
        >
          <option value="" disabled hidden>
            {field.label}
          </option>
          {field.options.map((el, index) => {
            return (
              <option key={field.label + "-" + index} value={el.value}>
                {el.name}
              </option>
            );
          })}
        </select>
      );
    });
  };
  renderFiltersContainer = () => {
    return this.state.filterList.map((el, index) => {
      return (
        <MyAccordion key={"accordion-" + index} title={el.label}>
          {this.renderFiltersContent(el.fields)}
        </MyAccordion>
      );
    });
  };

  render() {
    return (
      <div
        className={styles.mainContainer}
        style={{ backgroundImage: " url('img/listing_bg.png')" }}
      >
        <Header />
        <Container className={"my-5 text-center text-md-start"}>
          <Row>
            <Col sm="12" lg="6">
              <div className={"title-card mb-4"}>
                Le nostre <span className={styles.primary}>migliori</span>{" "}
                offerte
              </div>
              <div className={"light-text text-uppercase"}>
                Consulta le offerte e scegli l'auto su misura per te!
              </div>
            </Col>
          </Row>
        </Container>

        <Container className={styles.filterContainer}>
          <Row>
            <Col sm="12" lg="4">
              <MyAccordion
                alwaysOpen={true}
                subtitle={"Modifica il range"}
                title={"Prezzo"}
              >
                {this.state.loaded ? (
                  <div className={"mt-4"}>
                    <RangeSlider
                      min={this.state.priceRange[0]}
                      max={this.state.priceRange[1]}
                      current={[
                          (this.state.filters.priceRange[0] < this.state.priceRange[0]) ? this.state.priceRange[0]:this.state.filters.priceRange[0],
                          (this.state.filters.priceRange[1] < this.state.priceRange[1]) ? this.state.priceRange[1] : this.state.filters.priceRange[1],
                      ]}
                      onChange={(value) => {
                        this.setState((prevState) => ({
                          filters: {
                            ...prevState.filters,
                            priceRange: value,
                          },
                        }));
                      }}
                      onEndChange={() => {
                        this.search();
                      }}
                    />
                  </div>
                ) : null}
              </MyAccordion>
              {this.renderFiltersContainer()}
            </Col>
            <Col sm="12" lg="8">
              <AdContainer>
                {this.state.carList.map((el, index) => {
                  return <AdListing key={"listing-" + index} data={el} />;
                })}
              </AdContainer>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Listing);
