import * as React from "react";
import { Range, getTrackBackground } from "react-range";
import styles from "./index.module.scss";

const STEP = 100;

const RangerSlider = ({ min, max, onChange, current, onEndChange }) => {
  const [values, setValues] = React.useState([min, max]);
  const [receivedValue, setReceivedValues] = React.useState(false);

  if (current && !receivedValue) {
    setReceivedValues(true);
    setValues(current);
    if (onChange) {
      onChange(values);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={min}
        max={max}
        onChange={(values) => {
          setValues(values);
          if (onChange) {
            onChange(values);
          }
        }}
        onFinalChange={(value) => {
          if (onEndChange) {
            onEndChange(values);
          }
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              className={styles.track}
              style={{
                height: "6px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", "#1D1D28", "#ccc"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className={styles.thumbs}
            style={{
              ...props.style,
            }}
          >
            <div className={styles.label}>
              {props["aria-valuenow"] === props["aria-valuemax"]
                ? "Max"
                : values[props.key] + "\u20AC"}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default RangerSlider;
